import axios from 'axios';
const apiKey = 'b465cd8';
const apiURL = 'https://www.omdbapi.com/';


export const searchIMDb = async (query, year) =>
{
  try
  {
    const result = await axios.get(`${apiURL}?s=${query}&y=${year}&apikey=${apiKey}`);
    return result.data.Search;
  } catch (error)
  {
    throw error;
  }
};

export const getIMDbDetails = async (imdbID) =>
{
  try
  {
    const result = await axios.get(`${apiURL}?i=${imdbID}&plot=full&apikey=${apiKey}`);
    console.log(result.data)
    return result.data;
  } catch (error)
  {
    throw error;
  }
}

export const sendToDiscordWebhook = async (selectedResult, username) =>
{
  try
  {
    const webhookURL = 'https://discord.com/api/webhooks/1279477980304769104/BLHyrYFXU3GmNrprDUMlKtsIUT_4_Me1Bt4CHSQieiGRVgF7cCpv6_a6HY5f2v1IeyjF';

    const payload = {
      embeds: [
        {
          author: {
            name: "New Plex Request",
            url: "https://molex.cloud/plex",
            icon_url: "https://molex.cloud/2023/November/29/_6QE/plex-black.jpg"
          },
          title: selectedResult.Title,
          description: selectedResult.Plot,
          color: 0xffa500,
          thumbnail: { url: selectedResult.Poster },
          fields: [
            { name: 'Type', value: selectedResult.Type, inline: true },
            { name: 'Year', value: selectedResult.Year, inline: true },
            { name: 'IMDb Link', value: `[View on IMDb](https://www.imdb.com/title/${selectedResult.imdbID})` },
            { name: 'Requester', value: `${username ? username : 'Unknown'}` }
          ],
          timestamp: new Date().toISOString()
        }
      ]
    };

    const response = await fetch(webhookURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    });

    if (!response.ok)
    {
      throw new Error(`Error sending to Discord webhook: ${response.statusText}`);
    }
  } catch (error)
  {
    console.error(error);
  }

};